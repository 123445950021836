export type IMenu = {
  name: string;
  url: string;
  key: string;
};

export const menus: IMenu[] = [
  {
    name: "Home",
    url: "/",
    key: "home",
  },
  {
    name: "Creators",
    url: "/creators",
    key: "creators",
  },
  {
    name: "🔥Explore🔥",
    url: "/explore",
    key: "explore",
  },
];

export const footerMenu: IMenu[] = [
  {
    name: "About Us",
    url: "#",
    key: "aboutus",
  },
  {
    name: "Terms of Service",
    url: "#",
    key: "termsofservice",
  },
  {
    name: "Privacy",
    url: "#",
    key: "privacy",
  },
  {
    name: "Careers",
    url: "#",
    key: "careers",
  },
  {
    name: "Contact Us",
    url: "mailto:hello@artist3.io",
    key: "contactus",
  },
  {
    name: "FAQs",
    url: "#",
    key: "faqs",
  },
];
