import { ethers } from 'ethers'

import { simpleRpcProvider } from './providers'
import { getMart3Address, getArtist3NftAddress } from '../utils/addressHelpers'

import ARTIST3NFT_ABI from '../abis/Artist3NFT.json'
import MART3_ABI from '../abis/Mart3V2.json'

const getContract = (
  abi: any,
  address: string,
  signer?: ethers.Signer | ethers.providers.Provider,
) => {
  const signerOrProvider = signer ?? simpleRpcProvider
  return new ethers.Contract(address, abi, signerOrProvider)
}

export const getMart3Contract = (
  signer?: ethers.Signer | ethers.providers.Provider,
) => {
  return getContract(MART3_ABI, getMart3Address(), signer)
}

export const getArtist3NftContract = (
  signer?: ethers.Signer | ethers.providers.Provider,
) => {
  return getContract(ARTIST3NFT_ABI, getArtist3NftAddress(), signer)
}
