import { Web3Provider } from '@ethersproject/providers'
import { ChainId } from '@pancakeswap-libs/sdk'
import { InjectedConnector } from '@web3-react/injected-connector'
import { WalletConnectConnector } from '@web3-react/walletconnect-connector'
// import { WalletLinkConnector } from '@web3-react/walletlink-connector'
import { PortisConnector } from '@web3-react/portis-connector'
import { BscConnector } from '@binance-chain/bsc-connector'

import { FortmaticConnector } from './Fortmatic'
import { NetworkConnector } from './NetworkConnector'
import getLibrary from '../utils/getLibrary'
import getNodeUrl from '../utils/getRpcUrl'

// const NETWORK_URL = process.env.REACT_APP_NETWORK_URL
const NETWORK_URL = 'https://bsc-dataseed1.binance.org'
const FORMATIC_KEY = process.env.REACT_APP_FORTMATIC_KEY
const PORTIS_ID = process.env.REACT_APP_PORTIS_ID

const POLLING_INTERVAL = 12000
const rpcUrl = getNodeUrl()

// export const NETWORK_CHAIN_ID: number = parseInt(process.env.REACT_APP_CHAIN_ID ?? '1')
export const NETWORK_CHAIN_ID = 56

if (typeof NETWORK_URL === 'undefined') {
  throw new Error(
    `REACT_APP_NETWORK_URL must be a defined environment variable`,
  )
}

const NETWORK_ULS: { [chainId in ChainId]: string } = {
  [ChainId.MAINNET]: 'https://bsc-dataseed1.binance.org',
  [ChainId.BSCTESTNET]: 'https://data-seed-prebsc-1-s2.binance.org:8545/',
}

export const SUPPORTED_CHAIN_IDS =
  process.env.NODE_ENV === 'production'
    ? [ChainId.MAINNET]
    : [ChainId.MAINNET, ChainId.BSCTESTNET]

export const network = new NetworkConnector({
  urls: NETWORK_ULS,
  defaultChainId: ChainId.MAINNET,
})

let networkLibrary: Web3Provider | undefined
export function getNetworkLibrary(): Web3Provider {
  return (networkLibrary = networkLibrary ?? getLibrary(network.provider))
}

export const injected = new InjectedConnector({
  supportedChainIds: SUPPORTED_CHAIN_IDS,
})

// mainnet only
export const walletconnect = new WalletConnectConnector({
  rpc: { [ChainId.MAINNET as number]: rpcUrl as string },
  bridge: 'https://pancakeswap.bridge.walletconnect.org/',
  qrcode: true,
  pollingInterval: POLLING_INTERVAL,
})

export const bscConnector = new BscConnector({
  supportedChainIds: SUPPORTED_CHAIN_IDS,
})

// mainnet only
export const fortmatic = new FortmaticConnector({
  apiKey: FORMATIC_KEY ?? '',
  chainId: 1,
})

// mainnet only
export const portis = new PortisConnector({
  dAppId: PORTIS_ID ?? '',
  networks: [1],
})

// mainnet only
// export const walletlink = new WalletLinkConnector({
//   url: NETWORK_URL,
//   appName: 'LuaSwap',
//   appLogoUrl: 'https://raw.githubusercontent.com/tomochain/luaswap-interface/master/public/images/512x512_App_Icon.png'
// })
