import React from "react";
import { useClipboard, HStack, Text, Box } from "@chakra-ui/react";
import { BiCopy } from "react-icons/bi";
import { FcCheckmark } from "react-icons/fc";

type Props = {
  toCopy: string;
};

export const Copy = ({ toCopy }: Props) => {
  const { hasCopied, onCopy } = useClipboard(toCopy);

  return (
    <HStack style={{ cursor: "copy" }} onClick={onCopy}>
      <Text fontWeight="light" fontSize="sm">
        Copy Address
      </Text>
      <Box fontSize="lg">{hasCopied ? <FcCheckmark /> : <BiCopy />}</Box>
    </HStack>
  );
};
