import {
  configureStore,
  ThunkAction,
  Action,
  combineReducers,
  AnyAction,
  CombinedState,
  getDefaultMiddleware,
} from '@reduxjs/toolkit'
import priceReducer from '../state/price/priceSlice'
import balanceReducer from '../state/balance/balanceSlice'
import walletReducer from '../state/wallet/walletSlice'
import applicationReducer from '../state/application/reducer'
import multicallReducer from '../state/multicall/reducer'
import transactionReducer from '../state/transaction/transactionSlice'

const combinedReducer = combineReducers({
  wallet: walletReducer,
  price: priceReducer,
  balance: balanceReducer,
  application: applicationReducer,
  multicall: multicallReducer,
  transaction: transactionReducer,
})

const rootReducer = (state: RootState, action: AnyAction) => {
  if (action.type === 'wallet/defaultState') {
    state = {} as RootState
  }
  return combinedReducer(state, action)
}

export const store = configureStore({
  reducer: rootReducer as CombinedState<typeof combinedReducer>,
  middleware: [...getDefaultMiddleware()],
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof combinedReducer>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>
