import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { selectPrice, fetchPriceAsync } from "./priceSlice";

type ReturnProps = {
  price: number;
  fetchPrice: (asset: string) => void;
};

export const usePrice = (): ReturnProps => {
  const price = useAppSelector(selectPrice);
  const dispatch = useAppDispatch();

  function fetchPrice(asset: string) {
    dispatch(fetchPriceAsync(asset));
  }

  return { price, fetchPrice };
};
