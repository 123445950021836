import React from 'react'
import { Switch, Route } from 'react-router-dom'
import loadable from '@loadable/component'

import NoMatch from '../components/NoMatch'
import { Loader } from '../components/Loader'

const lazyLoading = (loader: any) => {
  return loadable(loader, {
    fallback: <Loader />,
  })
}

const routesPath = [
  {
    path: '/',
    key: 'home',
    exact: true,
    component: lazyLoading(() => import('../pages/home')),
  },
  {
    path: '/bids/:address',
    key: 'bids',
    exact: true,
    component: lazyLoading(() => import('../pages/bids')),
  },
  {
    path: '/creators',
    key: 'creators',
    exact: true,
    component: lazyLoading(() => import('../pages/creators')),
  },
  {
    path: '/creator/:username/collection/:id',
    key: 'collections',
    exact: true,
    component: lazyLoading(() => import('../pages/creators/collection')),
  },
  {
    path: '/creator/:address',
    key: 'profile',
    exact: true,
    component: lazyLoading(() => import('../pages/creators/profile')),
  },
  {
    path: '/creator/profile/edit',
    key: 'editprofile',
    exact: true,
    component: lazyLoading(() => import('../pages/creators/edit-profile')),
  },
  {
    path: '/explore',
    key: 'explore',
    exact: true,
    component: lazyLoading(() => import('../pages/artworks')),
  },
  {
    path: '/create',
    key: 'create',
    exact: true,
    component: lazyLoading(() => import('../pages/create-nft')),
  },
]

const routes = (
  <div className="mag-sizing">
    <Switch>
      {routesPath.map((routePath) => (
        <Route
          path={routePath.path}
          exact={routePath.exact}
          key={routePath.key}
          component={routePath.component}
        />
      ))}
      <Route component={NoMatch} />
    </Switch>
  </div>
)

export default routes
