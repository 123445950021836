import React from "react";
import { Spinner, Flex } from "@chakra-ui/react";

export const Loader = () => {
  return (
    <Flex justify="flex-end">
      <Spinner color="orange.500" />
    </Flex>
  );
};
