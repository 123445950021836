import React from "react";
import { Box } from "@chakra-ui/react";
import ReactPlayer from "react-player/lazy";

type Props = {
  url: string;
  size?: string;
};

const config = {
  file: {
    attributes: {
      autoPlay: true,
      controlsList: ["nodownload"],
    },
  },
};

export const VideoPlayer = ({ url, size }: Props) => {
  return (
    <Box boxSize={`${size}px`}>
      <ReactPlayer
        url={url}
        config={config}
        playing={true}
        width="100%"
        height="100%"
        muted={true}
      />
    </Box>
  );
};
