import { useMemo } from "react";

import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  updateTransactionStatus,
  selectTransactionStatus,
} from "./transactionSlice";

export const useUpdateTransactionStatus = (): {
  status: boolean;
  updateStatus: (status: boolean) => void;
} => {
  const status = useAppSelector(selectTransactionStatus);
  const dispatch = useAppDispatch();

  const updateStatus = useMemo(
    () => (state: boolean) => {
      dispatch(updateTransactionStatus(state));
    },
    [dispatch]
  );

  return { status, updateStatus };
};
