import { UnsupportedChainIdError, useWeb3React } from '@web3-react/core'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Box,
  Text,
  HStack,
  Button,
  VStack,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from '@chakra-ui/react'
import { AiOutlineThunderbolt } from 'react-icons/ai'
import { IoWalletOutline } from 'react-icons/io5'
import { ImCircleDown } from 'react-icons/im'

import { NetworkContextName } from '../../constants'
import { useWalletModalToggle } from '../../state/application/hooks'
import { shortenAddress } from '../../utils'
import { useUpdateTransactionStatus } from '../../state/transaction/hooks'
import { useETHBalances, useUpdateWallet } from '../../state/wallet/hooks'
import { useBalance } from '../../state/balance/hooks'

import Identicon from '../Identicon'
import WalletModal from '../WalletModal'

function Web3StatusInner() {
  const { t } = useTranslation()
  const { account, connector, error, active } = useWeb3React()
  const { status: pendingTransactionStatus } = useUpdateTransactionStatus()
  const { balance, updateWalletBalance } = useBalance()
  const { updateWalletState } = useUpdateWallet()

  const toggleWalletModal = useWalletModalToggle()
  const userBnbBalance = useETHBalances(account ? [account] : [])?.[
    account ?? ''
  ]

  useEffect(() => {
    const { ethereum } = window
    if (userBnbBalance) {
      updateWalletBalance(parseFloat(userBnbBalance?.toFixed(3)))
    }

    if (ethereum && !account) {
      updateWalletBalance(0)
      updateWalletState('')
    }
  }, [account, active, updateWalletBalance, updateWalletState, userBnbBalance])

  if (account) {
    return (
      <HStack
        spacing="10px"
        onClick={toggleWalletModal}
        justify="stretch"
        bgColor="whiteAlpha.200"
        px="3"
        py="1"
        borderRadius="20px"
        boxShadow="base"
        className={pendingTransactionStatus ? 'glow-on-hover' : undefined}
      >
        {/* {pendingTransactionStatus && <Spinner size="sm" color="orange.400" />} */}
        {/* <Text fontSize="sm" color="orange.500" fontWeight="bold">
          BSC
        </Text> */}
        <Box color="orange.200" fontSize="3xl">
          <IoWalletOutline />
        </Box>
        <VStack spacing={0}>
          {account && userBnbBalance ? (
            <Text fontSize="md" fontWeight="bold">
              {balance} BNB
            </Text>
          ) : null}
          <Text fontWeight="light" fontSize="sm">
            {shortenAddress(account)}
          </Text>
        </VStack>
        {connector && <Identicon />}
      </HStack>
    )
  } else if (error) {
    return (
      <Button
        colorScheme="red"
        leftIcon={<AiOutlineThunderbolt />}
        onClick={toggleWalletModal}
      >
        {error instanceof UnsupportedChainIdError ? 'Wrong Network' : 'Error'}
      </Button>
    )
  } else {
    return (
      <HStack spacing="30px">
        <Menu isLazy>
          <MenuButton
            as={Button}
            rightIcon={
              <Box>
                <ImCircleDown />
              </Box>
            }
            aria-label="Options"
            bgColor="inherit"
            borderWidth="2px"
            borderColor="orange.200"
            color="orange.200"
            minW="150px"
            _hover={{ bg: 'gray.800' }}
            _expanded={{ bg: 'gray.800' }}
            _focus={{ boxShadow: 'outline' }}
          >
            BSC Chain
          </MenuButton>
          <MenuList color="white" bgColor="black">
            <Text ml="3" fontSize="lg" color="whiteAlpha.600">
              Coming Soon...
            </Text>
            <MenuItem _hover={{ bg: 'gray.500' }}>Ethereum</MenuItem>
            <MenuItem _hover={{ bg: 'gray.500' }}>Polygon</MenuItem>
            <MenuItem _hover={{ bg: 'gray.500' }}>Solana</MenuItem>
          </MenuList>
        </Menu>
        <Button
          size="lg"
          border="2px solid black"
          color="black"
          id="connect-wallet"
          onClick={toggleWalletModal}
          isFullWidth
        >
          <Text>{t('Connect Wallet')}</Text>
        </Button>
      </HStack>
    )
  }
}

export const Web3Status = () => {
  const { active } = useWeb3React()
  const contextNetwork = useWeb3React(NetworkContextName)

  if (!contextNetwork.active && !active) {
    return null
  }

  return (
    <Box __css={{ cursor: 'pointer' }}>
      <Web3StatusInner />
      <WalletModal ENSName={undefined} />
    </Box>
  )
}
