import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

import { fetchPrice } from "./priceApi";

export interface PriceState {
  price: number;
  updatedAt: number;
  isRefreshing: boolean;
}

const initialState: PriceState = {
  price: 0,
  updatedAt: 0,
  isRefreshing: false,
};

export const fetchPriceAsync = createAsyncThunk(
  "price/latest",
  async (asset: string) => {
    const response = await fetchPrice(asset);
    return response.price;
  }
);

export const priceSlice = createSlice({
  name: "price",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPriceAsync.pending, (state) => {
        state.isRefreshing = true;
      })
      .addCase(fetchPriceAsync.fulfilled, (state, action) => {
        state.price = action.payload;
        state.updatedAt = Date.now();
        state.isRefreshing = false;
      });
  },
});

export const selectPrice = (state: RootState) => state.price.price;

export default priceSlice.reducer;
