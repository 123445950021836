import React, { MouseEventHandler } from "react";
import { Box, Text, HStack, VStack, Link, Image } from "@chakra-ui/react";

type Props = {
  link?: string | null;
  clickable?: boolean;
  onClick?: MouseEventHandler<HTMLDivElement> | undefined;
  color: string;
  icon: string;
  active?: boolean;
  id: string;
  header: React.ReactNode;
  subheader: React.ReactNode | null;
};

export const WalletOption = ({
  link = null,
  clickable = true,
  onClick = undefined,
  icon,
  active = false,
  id,
  header,
  subheader = null,
}: Props) => {
  const content = (
    <VStack spacing="10px" align="stretch">
      <HStack
        justify="space-between"
        py="2"
        px="4"
        border="2px solid black"
        _hover={{
          cursor: "pointer",
          background: `${active ? null : "black"}`,
          color: `${active ? null : "white"}`,
        }}
        width="100%"
        id={id}
        onClick={onClick}
        disabled={active && !clickable}
      >
        <Box width="100%">
          <HStack align="center">
            {active ? (
              <Box w="8px" h="8px" borderRadius="50%" bgColor="green.400" />
            ) : (
              ""
            )}
            <Text fontWeight="bold">{header}</Text>
          </HStack>
          {subheader && <Text>{subheader}</Text>}
        </Box>
        <Box borderRadius="full" border="1px solid black" py="1" px="7px">
          <Image boxSize="24px" src={icon} alt="Icon" objectFit="contain" />
        </Box>
      </HStack>
    </VStack>
  );

  if (link) {
    return (
      <Link href={link} isExternal>
        {content}
      </Link>
    );
  }
  return content;
};
