import { AbstractConnector } from "@web3-react/abstract-connector";
import { UnsupportedChainIdError, useWeb3React } from "@web3-react/core";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";
import {
  VStack,
  Link,
  Text,
  Modal,
  ModalOverlay,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  IconButton,
  Box,
} from "@chakra-ui/react";
import { BiArrowBack } from "react-icons/bi";
import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import ReactGA from "react-ga";

import MetamaskIcon from "../../assets/images/metamask.png";
import { fortmatic, injected, portis } from "../../connectors";
import { OVERLAY_READY } from "../../connectors/Fortmatic";
import { SUPPORTED_WALLETS } from "../../constants";
import usePrevious from "../../hooks/usePrevious";
import { ApplicationModal } from "../../state/application/actions";
import {
  useModalOpen,
  useWalletModalToggle,
} from "../../state/application/hooks";
import AccountDetails from "../AccountDetails";

import { WalletOption } from "./WalletOption";
import PendingView from "./PendingView";

const WALLET_VIEWS = {
  OPTIONS: "options",
  OPTIONS_SECONDARY: "options_secondary",
  ACCOUNT: "account",
  PENDING: "pending",
};

export default function WalletModal({ ENSName }: { ENSName?: string }) {
  // important that these are destructed from the account-specific web3-react context
  const { active, account, connector, activate, error } = useWeb3React();

  const [walletView, setWalletView] = useState(WALLET_VIEWS.ACCOUNT);

  const [pendingWallet, setPendingWallet] = useState<
    AbstractConnector | undefined
  >();

  const [pendingError, setPendingError] = useState<boolean>();

  const walletModalOpen = useModalOpen(ApplicationModal.WALLET);
  const toggleWalletModal = useWalletModalToggle();

  const previousAccount = usePrevious(account);

  // close on connection, when logged out before
  useEffect(() => {
    if (account && !previousAccount && walletModalOpen) {
      toggleWalletModal();
    }
  }, [account, previousAccount, toggleWalletModal, walletModalOpen]);

  // always reset to account view
  useEffect(() => {
    if (walletModalOpen) {
      setPendingError(false);
      setWalletView(WALLET_VIEWS.ACCOUNT);
    }
  }, [walletModalOpen]);

  // close modal when a connection is successful
  const activePrevious = usePrevious(active);
  const connectorPrevious = usePrevious(connector);
  useEffect(() => {
    if (
      walletModalOpen &&
      ((active && !activePrevious) ||
        (connector && connector !== connectorPrevious && !error))
    ) {
      setWalletView(WALLET_VIEWS.ACCOUNT);
    }
  }, [
    setWalletView,
    active,
    error,
    connector,
    walletModalOpen,
    activePrevious,
    connectorPrevious,
  ]);

  const tryActivation = async (connector: AbstractConnector | undefined) => {
    let name = "";
    Object.keys(SUPPORTED_WALLETS).map((key) => {
      if (connector === SUPPORTED_WALLETS[key].connector) {
        return (name = SUPPORTED_WALLETS[key].name);
      }
      return true;
    });
    // log selected wallet
    ReactGA.event({
      category: "Wallet",
      action: "Change Wallet",
      label: name,
    });
    setPendingWallet(connector); // set wallet for pending view
    setWalletView(WALLET_VIEWS.PENDING);

    // if the connector is walletconnect and the user has already tried to connect, manually reset the connector
    if (
      connector instanceof WalletConnectConnector &&
      connector.walletConnectProvider?.wc?.uri
    ) {
      connector.walletConnectProvider = undefined;
    }

    connector &&
      activate(connector, undefined, true).catch((error) => {
        if (error instanceof UnsupportedChainIdError) {
          activate(connector); // a little janky...can't use setError because the connector isn't set
        } else {
          setPendingError(true);
        }
      });
  };

  // close wallet modal if fortmatic modal is active
  useEffect(() => {
    fortmatic.on(OVERLAY_READY, () => {
      toggleWalletModal();
    });
  }, [toggleWalletModal]);

  // get wallets user can switch too, depending on device/browser
  function getOptions() {
    const isMetamask = window.ethereum && window.ethereum.isMetaMask;
    return Object.keys(SUPPORTED_WALLETS).map((key) => {
      const option = SUPPORTED_WALLETS[key];
      // check for mobile options
      if (isMobile) {
        //disable portis on mobile for now
        if (option.connector === portis) {
          return null;
        }

        if (!window.web3 && !window.ethereum && option.mobile) {
          return (
            <WalletOption
              onClick={() => {
                option.connector !== connector &&
                  !option.href &&
                  tryActivation(option.connector);
              }}
              id={`connect-${key}`}
              key={key}
              active={option.connector && option.connector === connector}
              color={option.color}
              link={option.href}
              header={option.name}
              subheader={null}
              icon={option.iconURL}
            />
          );
        }
        return null;
      }

      // overwrite injected when needed
      if (option.connector === injected) {
        // don't show injected if there's no injected provider
        if (!(window.web3 || window.ethereum)) {
          if (option.name === "MetaMask") {
            return (
              <WalletOption
                id={`connect-${key}`}
                key={key}
                color={"#E8831D"}
                header={"Install Metamask"}
                subheader={null}
                link={"https://metamask.io/"}
                icon={MetamaskIcon}
              />
            );
          } else {
            return null; //dont want to return install twice
          }
        }
        // don't return metamask if injected provider isn't metamask
        else if (option.name === "MetaMask" && !isMetamask) {
          return null;
        }
        // likewise for generic
        else if (option.name === "Injected" && isMetamask) {
          return null;
        }
      }

      // return rest of options
      return (
        !isMobile &&
        !option.mobileOnly && (
          <WalletOption
            id={`connect-${key}`}
            onClick={() => {
              option.connector === connector
                ? setWalletView(WALLET_VIEWS.ACCOUNT)
                : !option.href && tryActivation(option.connector);
            }}
            key={key}
            active={option.connector === connector}
            color={option.color}
            link={option.href}
            header={option.name}
            subheader={null} //use option.descriptio to bring back multi-line
            icon={option.iconURL}
          />
        )
      );
    });
  }

  function getModalContent() {
    if (error) {
      return (
        <ModalContent borderRadius="xl">
          <ModalHeader>
            {error instanceof UnsupportedChainIdError
              ? "Wrong Network"
              : "Error connecting"}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {error instanceof UnsupportedChainIdError ? (
              <h5>Please switch to Binance Smart Chain Network.</h5>
            ) : (
              "Error connecting. Try refreshing the page."
            )}
          </ModalBody>
        </ModalContent>
      );
    }
    if (account && walletView === WALLET_VIEWS.ACCOUNT) {
      return (
        <AccountDetails
          toggleWalletModal={toggleWalletModal}
          ENSName={ENSName}
          openOptions={() => setWalletView(WALLET_VIEWS.OPTIONS)}
        />
      );
    }
    return (
      <ModalContent borderRadius="xl">
        {walletView !== WALLET_VIEWS.ACCOUNT ? (
          <ModalHeader>
            <IconButton
              aria-label="back"
              icon={
                <Box fontSize="lg">
                  <BiArrowBack />
                </Box>
              }
              onClick={() => {
                setPendingError(false);
                setWalletView(WALLET_VIEWS.ACCOUNT);
              }}
              variant="unstyled"
            />
          </ModalHeader>
        ) : (
          <ModalHeader>
            <Text fontSize="lg" fontWeight="bold">
              Connect to a Wallet
            </Text>
          </ModalHeader>
        )}
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing="30px" align="stretch">
            <Box
              as="span"
              fontWeight="semibold"
              fontSize="sm"
              bgColor="gray.100"
              textAlign="center"
              borderRadius="8px"
              p="3"
            >
              <Text as="span">
                By connecting a wallet, you agree to Artist3 Labs’{" "}
                <Link color="orange.600" href="#" isExternal>
                  Terms of Service
                </Link>{" "}
                and acknowledge that you have read and understand the{" "}
                <Link color="orange.600" href="#" isExternal>
                  artist3 labs disclaimer
                </Link>
                .
              </Text>
            </Box>
            {walletView === WALLET_VIEWS.PENDING ? (
              <PendingView
                connector={pendingWallet}
                error={pendingError}
                setPendingError={setPendingError}
                tryActivation={tryActivation}
              />
            ) : (
              <VStack spacing="10px" align="stretch" pb="4">
                {getOptions()}
              </VStack>
            )}
          </VStack>
        </ModalBody>
      </ModalContent>
    );
  }

  return (
    <Modal isOpen={walletModalOpen} onClose={toggleWalletModal} isCentered>
      <ModalOverlay />
      {getModalContent()}
    </Modal>
  );
}
