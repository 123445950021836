import React from "react";
import { Box } from "@chakra-ui/react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

import { VideoPlayer } from "./VideoPlayer";

type Props = {
  url: string;
  alt?: string;
  height?: string;
  width?: string;
  objectFit?: "fill" | "contain" | "cover" | "none" | "scale-down";
  style?: Partial<React.CSSProperties>;
};

export const Art = ({
  url,
  alt,
  height,
  objectFit = "cover",
  width = "100%",
  style,
}: Props) => {
  const isImage = /\.(gif|jpe?g|tiff?|png|webp|bmp)$/i.test(url);

  return (
    <Box>
      {isImage ? (
        <LazyLoadImage
          alt={alt}
          effect="blur"
          src={url}
          style={{
            objectFit,
            width,
            height,
            objectPosition: "center",
            ...style,
          }}
        />
      ) : (
        <VideoPlayer url={url} />
      )}
    </Box>
  );
};
