import React, { useRef } from 'react'
import { useHistory, useLocation } from 'react-router'
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Button,
  IconButton,
  useDisclosure,
  VStack,
  Box,
  Image,
} from '@chakra-ui/react'
import { HamburgerIcon } from '@chakra-ui/icons'

import { menus } from '../services/menu'
import { Web3Status } from '../Web3Status'
import { useActiveWeb3React } from '../../hooks'

export const LeftMenu = () => {
  const history = useHistory()
  const { pathname } = useLocation()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { account } = useActiveWeb3React()
  const btnRef = useRef() as any

  const onNaviagion = (url: string) => {
    history.push(url)
    onClose()
  }

  return (
    <Box>
      <IconButton
        variant="ghost"
        color="white"
        aria-label="Hamburger menu"
        icon={<HamburgerIcon fontSize="3xl" onClick={onOpen} />}
      />

      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader>
            <Box p="2">
              <Image
                boxSize="50px"
                objectFit="contain"
                src="../../../mart.png"
                alt="mart3 logo"
              />
            </Box>
          </DrawerHeader>
          <DrawerCloseButton />
          <DrawerBody>
            <VStack spacing="10px" h="100vh">
              {menus.map((menu) => (
                <Button
                  key={menu.key}
                  variant="ghost"
                  textColor="black"
                  colorScheme="blackAlpha"
                  isActive={menu.url === pathname}
                  _active={{
                    backgroundColor: 'black',
                    color: 'white',
                  }}
                  onClick={() => onNaviagion(menu.url)}
                  size="lg"
                  fontSize="1xl"
                  fontWeight="bold"
                  isFullWidth
                >
                  {menu.name}
                </Button>
              ))}
              {account && (
                <Button
                  key="bids"
                  variant="ghost"
                  textColor="black"
                  fontWeight="bold"
                  colorScheme="blackAlpha"
                  isActive={`/bids/${account}` === pathname}
                  _active={{
                    backgroundColor: 'black',
                    color: 'white',
                  }}
                  onClick={() => onNaviagion(`/bids/${account}`)}
                  size="lg"
                  fontSize="1xl"
                  isFullWidth
                >
                  Bids
                </Button>
              )}
            </VStack>
          </DrawerBody>
          <DrawerFooter>
            <Box w="100%">
              <Web3Status />
            </Box>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </Box>
  )
}
