import React from "react";
import {
  Flex,
  Link,
  HStack,
  Text,
  VStack,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import { FaTelegramPlane, FaDiscord, FaMedium, FaReddit } from "react-icons/fa";
import { FiFacebook, FiTwitter, FiInstagram, FiYoutube } from "react-icons/fi";

import { footerMenu } from "../services/menu";
import { SocialLink } from "./SocialLink";
import { Art } from "../Art";

export const Footer = () => {
  return (
    <Flex
      as="footer"
      py="10"
      px="6"
      bgColor="gray.50"
      direction="column"
      justify="center"
    >
      <VStack spacing="30px" align="center">
        <Link href="https://artist3.io" isExternal>
          <Art
            url="../../../footer-logo.png"
            alt="mart3 footer logo"
            objectFit="contain"
            height="40px"
            width="40px"
          />
        </Link>
        <Text color="gray.500" fontSize="sm" maxW="382px" textAlign="center">
          Mart3 provides new and innovative avenues for trading NFTS, compelling
          digital arts and animated content inspired by stories, consciousness,
          vintage art, and iconic history for a modern world.
        </Text>
        <Wrap spacing="20px" wrap="wrap" justify="space-evenly">
          {footerMenu.map((footer) => (
            <WrapItem key={footer.key}>
              <Link fontSize="sm" href={footer.url}>
                {footer.name}
              </Link>
            </WrapItem>
          ))}
        </Wrap>
        <HStack spacing="10px" wrap="wrap" justify="space-evenly">
          <SocialLink label="artist3 medium link" url="#" icon={<FaMedium />} />
          <SocialLink
            label="artist3 telegram link"
            url="https://t.me/joinchat/L005Id_rzgZhZGM0"
            icon={<FaTelegramPlane />}
          />
          <SocialLink
            url="#"
            label="artist3 facebook link"
            icon={<FiFacebook />}
          />
          <SocialLink
            label="artist3 twitter link"
            icon={<FiTwitter />}
            url="https://twitter.com/Artist3_io"
          />
          <SocialLink
            label="artist3 instagram link"
            icon={<FiInstagram />}
            url="https://www.instagram.com/artist3.io/"
          />
          <SocialLink
            label="artist3 discord link"
            icon={<FaDiscord />}
            url="#"
          />
          <SocialLink
            label="artist3 youtube link"
            icon={<FiYoutube />}
            url="https://www.youtube.com/channel/UClno6w_a6oiRNry179XNm6g"
          />
          <SocialLink
            label="artist3 reddit link"
            icon={<FaReddit />}
            url="https://www.reddit.com/user/artist3_io"
          />
        </HStack>
      </VStack>
    </Flex>
  );
};
