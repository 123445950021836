import React from 'react'
import { useLocation } from 'react-router-dom'
import { Box, Flex, Spacer } from '@chakra-ui/react'

import { NavBar } from '../components/NavBar'
import { Footer } from '../components/Footer'

type Props = {
  children: React.ReactNode
}

// routes definition where Navbar display is disabled
const navBarRoutes = ['/dont']

export const Layout = ({ children }: Props) => {
  const { pathname } = useLocation()

  const cannotShowNavBar = () => {
    return navBarRoutes.includes(pathname)
  }

  return (
    <>
      {!cannotShowNavBar() && (
        <Flex direction="column" height="100vh">
          <Box bgColor="black" zIndex="100">
            <NavBar />
          </Box>
          <Box my="8" mx="3">
            {children}
          </Box>
          <Spacer />
          <Box mt="8">
            <Footer />
          </Box>
        </Flex>
      )}
    </>
  )
}
