import React, { ReactElement, JSXElementConstructor } from "react";
import { IconButton, Link } from "@chakra-ui/react";

type Props = {
  icon: ReactElement<any, string | JSXElementConstructor<any>> | undefined;
  label: string;
  url: string;
};

export const SocialLink = ({ icon, label, url }: Props) => {
  return (
    <Link href={url} isExternal>
      <IconButton variant="unstyled" aria-label={label} icon={icon} />
    </Link>
  );
};
