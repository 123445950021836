import { Currency, CurrencyAmount } from '@uniswap/sdk-core'
import JSBI from 'jsbi'
import { useMemo } from 'react'
import { useMulticall2Contract } from '../../hooks/useContract'
import { isAddress } from '../../utils'
import { useSingleContractMultipleData } from '../multicall/hooks'
import {
  updateWalletStatus,
  sellectWalletAddress,
  defaultState,
} from './walletSlice'
import { useAppSelector, useAppDispatch } from '../../app/hooks'
/**
 * Returns a map of the given addresses to their eventually consistent ETH balances.
 */
export function useETHBalances(
  uncheckedAddresses?: (string | undefined)[],
): {
  [address: string]: CurrencyAmount<Currency> | undefined
} {
  const multicallContract = useMulticall2Contract()

  const addresses: string[] = useMemo(
    () =>
      uncheckedAddresses
        ? uncheckedAddresses
            .map(isAddress)
            .filter((a): a is string => a !== false)
            .sort()
        : [],
    [uncheckedAddresses],
  )

  const results = useSingleContractMultipleData(
    multicallContract,
    'getEthBalance',
    addresses.map((address) => [address]),
  )

  return useMemo(
    () =>
      addresses.reduce<{ [address: string]: CurrencyAmount<Currency> }>(
        (memo, address, i) => {
          const value = results?.[i]?.result?.[0]
          if (value)
            memo[address] = CurrencyAmount.ether(JSBI.BigInt(value.toString()))
          return memo
        },
        {},
      ),
    [addresses, results],
  )
}

export const useUpdateWallet = (): {
  updateWalletState: (state: string) => void
  address: string
} => {
  const address = useAppSelector(sellectWalletAddress)
  const dispatch = useAppDispatch()

  const updateWalletState = (state: string) => {
    dispatch(updateWalletStatus(state))
  }

  return { address, updateWalletState }
}

export const useDefaultState = (): { defaultReduxState: Function } => {
  const dispatch = useAppDispatch()
  const defaultReduxState = () => {
    dispatch(defaultState)
  }

  return { defaultReduxState }
}
