import {
  Artist3NftAddress,
  Artist3TestAddress,
  Mart3Address,
  Mart3TestAddress,
} from '.'

export const addresses = {
  mart3: {
    97: Mart3TestAddress,
    56: Mart3Address,
  },
  artist3Nft: {
    97: Artist3TestAddress,
    56: Artist3NftAddress,
  },
  multiCall: {
    97: '0x2DdDA466870F5Aca28ccEd1BD9749De2f6c90312',
    56: '0x2DdDA466870F5Aca28ccEd1BD9749De2f6c90312',
  },
}
