import { ChainId } from '@pancakeswap-libs/sdk'

import { addresses } from '../constants/contracts'

interface Address {
  97: string
  56: string
}

export const getAddress = (address: Address): string => {
  const chainId =
    process.env.NODE_ENV === 'development'
      ? ChainId.BSCTESTNET
      : ChainId.MAINNET
  return address[chainId] ? address[chainId] : address[ChainId.MAINNET]
}

export const getMart3Address = () => {
  return getAddress(addresses.mart3)
}

export const getArtist3NftAddress = () => {
  return getAddress(addresses.artist3Nft)
}

export const getMulticallAddress = () => {
  return getAddress(addresses.multiCall)
}
