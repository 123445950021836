import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  split,
} from "@apollo/client";
import { WebSocketLink } from "@apollo/client/link/ws";
import { setContext } from "@apollo/client/link/context";
import { getMainDefinition } from "apollo-utilities";

import { store } from "./app/store";

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_GRAPHURL,
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exist

  const state = store.getState();
  const address = state.wallet.address;
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      Authorization: address ? address : "anonymous",
    },
  };
});

const wsLink = new WebSocketLink({
  uri: `${process.env.REACT_APP_WS_GRAPHURL}`,
  options: {
    reconnect: true,
    connectionParams: async () => {
      const state = store.getState();
      const address = state.wallet.address;
      return {
        headers: {
          Authorization: address ? address : "anonymous",
        },
      };
    },
  },
});

const link = split(
  // split based on operation type
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  authLink.concat(httpLink)
);

const cache = new InMemoryCache();

const client = new ApolloClient({
  link,
  cache,
});

export { client, cache };
