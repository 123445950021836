import { useCallback } from 'react'
import { useWeb3React } from '@web3-react/core'

import { useDefaultState } from '../state/wallet/hooks'
import { connectorsByName } from '../constants'

const useAuth = () => {
  const { deactivate } = useWeb3React()
  const { defaultReduxState } = useDefaultState()

  const logout = useCallback(() => {
    defaultReduxState()
    deactivate()
    // This localStorage key is set by @web3-react/walletconnect-connector
    if (window.localStorage.getItem('walletconnect')) {
      connectorsByName.walletconnect.close()
      connectorsByName.walletconnect.walletConnectProvider = null
    }
  }, [deactivate, defaultReduxState])

  return { logout }
}

export default useAuth
