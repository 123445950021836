import { extendTheme, withDefaultColorScheme } from "@chakra-ui/react";

import style from "./styles";

const overrides = {
  styles: style,
};

const customTheme = extendTheme(
  overrides,
  withDefaultColorScheme({ colorScheme: "orange" })
);

export default customTheme;
