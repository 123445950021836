import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../app/store'

type Status = 'connected' | 'disconnected'

export interface WalletState {
  walletState: Status
  address: string
}

const initialState: WalletState = {
  walletState: 'disconnected',
  address: '',
}

export const walletSlice = createSlice({
  name: 'wallet',
  initialState,
  reducers: {
    updateWalletStatus: (state, action: PayloadAction<string>) => {
      if (action.payload) {
        state.address = action.payload
        state.walletState = 'connected'
      } else {
        state.address = ''
        state.walletState = 'disconnected'
      }
    },
    defaultState: (state) => {},
  },
})

export const { updateWalletStatus, defaultState } = walletSlice.actions

export const selletWalletStatus = (state: RootState) => state.wallet.walletState

export const sellectWalletAddress = (state: RootState) => state.wallet.address

export default walletSlice.reducer
