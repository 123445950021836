import React, { Suspense } from 'react'
import { ChakraProvider, Box, Spinner, Center } from '@chakra-ui/react'
import { BrowserRouter as Router } from 'react-router-dom'

import mart3Theme from './theme'
import routes from './routes'
import { Layout } from './Layout'
import Web3ReactManager from './components/Web3ReactManager'
import ErrorBoundary from './components/ErrorBoundary'

const SupenseFallback = () => (
  <Box h="100vh">
    <Center>
      <Spinner size="lg" />
    </Center>
  </Box>
)

export const App = () => (
  <ChakraProvider theme={mart3Theme}>
    <ErrorBoundary>
      <Suspense fallback={<SupenseFallback />}>
        <Router>
          <Web3ReactManager>
            <Layout>{routes}</Layout>
          </Web3ReactManager>
        </Router>
      </Suspense>
    </ErrorBoundary>
  </ChakraProvider>
)
