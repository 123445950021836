import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

export interface TransactionState {
  status: boolean;
}

const initialState: TransactionState = {
  status: false,
};

export const transactionSlice = createSlice({
  name: "transaction",
  initialState,
  reducers: {
    updateTransactionStatus: (state, action: PayloadAction<boolean>) => {
      state.status = action.payload;
    },
  },
});

export const { updateTransactionStatus } = transactionSlice.actions;

export const selectTransactionStatus = (state: RootState) =>
  state.transaction.status;

export default transactionSlice.reducer;
