import React from 'react'
import { useLocation, useHistory, Link } from 'react-router-dom'
import {
  Flex,
  Box,
  Spacer,
  Button,
  HStack,
  useMediaQuery,
  Text,
} from '@chakra-ui/react'
import { useWeb3React } from '@web3-react/core'

// import { ColorModeSwitcher } from "../../ColorModeSwitcher";
import { menus } from '../services/menu'
import { useActiveWeb3React } from '../../hooks'
import { Web3Status } from '../Web3Status'
import { LeftMenu } from './LeftMenu'
import { Art } from '../Art'

export const NavBar = () => {
  const history = useHistory()
  const { pathname } = useLocation()
  const [isMobile, isDesktop, isBrowser] = useMediaQuery([
    '(max-width: 790px)',
    '(min-width: 968px)',
    '(display-mode: browser)',
  ])
  const { account, active } = useActiveWeb3React()
  const { chainId } = useWeb3React()

  return (
    <header className="mag-sizing">
      <Flex align="center" mr="2" color="white">
        <Link to="/">
          <HStack p="2">
            <Art
              url="../../../mart.png"
              alt="mart3 logo"
              objectFit="contain"
              height="50px"
              width="50px"
            />
            {!isMobile && (
              <Text fontSize="lg" fontWeight="bold">
                mart3
              </Text>
            )}
          </HStack>
        </Link>
        <Spacer />
        <Box>
          {!isMobile && (
            <HStack spacing="2" justify="center">
              {menus.map((menu) => (
                <Button
                  key={menu.key}
                  variant="ghost"
                  textColor="white"
                  colorScheme="blackAlpha"
                  fontWeight="bold"
                  isActive={menu.url === pathname}
                  _active={{
                    backgroundColor: 'black',
                    color: 'orange.500',
                  }}
                  onClick={() => history.push(menu.url)}
                >
                  {menu.name}
                </Button>
              ))}
            </HStack>
          )}
        </Box>
        <Spacer />
        <Box>
          {isDesktop && isBrowser && (
            <HStack spacing="30px">
              {active && chainId && (
                <>
                  <Button
                    variant="unstyled"
                    fontWeight="bold"
                    onClick={() => history.push(`/bids/${account}`)}
                  >
                    Bids
                  </Button>
                  <Button
                    bgGradient="linear(to-l, #7928CA, #FF0080)"
                    borderRadius="3xl"
                    mr="20px"
                    size="lg"
                    onClick={() => history.push('/create')}
                  >
                    Create
                  </Button>
                </>
              )}
              <Web3Status />
            </HStack>
          )}
          {/* {account && isMobile && <Web3Status />} */}
          {isMobile && <LeftMenu />}
          {/* <ColorModeSwitcher justifySelf="flex-end" /> */}
        </Box>
      </Flex>
    </header>
  )
}
