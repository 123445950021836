function mode(lightMode: string, darkMode: string) {
  return function (props: any): string {
    return props.colorMode === 'dark' ? darkMode : lightMode
  }
}

const styles = {
  global: (props: any) => ({
    body: {
      fontFamily: `-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu,
        Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif`,
      color: mode('gray.800', 'whiteAlpha.900')(props),
      bg: mode('gray.50', 'gray.800')(props),
      lineHeight: 'base',
      // maxWidth: "1280px",
      // margin: "0 auto",
      '--chakra-ring-color': 'red',
    },
    '*::placeholder': {
      color: mode('gray.400', 'whiteAlpha.400')(props),
    },
    '*::before, &::after': {
      borderColor: mode('gray.200', 'whiteAlpha.300')(props),
      wordWrap: 'break-word',
    },
    ':focus:not(:focus-visible):not([role="dialog"]):not([role="menu"])': {
      boxShadow: 'none !important',
    },
  }),
}

export default styles
