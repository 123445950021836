import { AbstractConnector } from "@web3-react/abstract-connector";
import React from "react";
import { Spinner, HStack, Text, VStack, Button, Box } from "@chakra-ui/react";

import { WalletOption } from "./WalletOption";
import { SUPPORTED_WALLETS } from "../../constants";
import { injected } from "../../connectors";

export default function PendingView({
  connector,
  error = false,
  setPendingError,
  tryActivation,
}: {
  connector?: AbstractConnector;
  error?: boolean;
  setPendingError: (error: boolean) => void;
  tryActivation: (connector: AbstractConnector) => void;
}) {
  const isMetamask = window?.ethereum?.isMetaMask;

  return (
    <VStack spacing="20px" justify="center" pb="10" align="stretch">
      {error ? (
        <Box alignSelf="center">
          <Text color="red.500">Error connecting.</Text>
          <Button
            variant="ghost"
            colorScheme="gray"
            onClick={() => {
              setPendingError(false);
              connector && tryActivation(connector);
            }}
          >
            Try Again
          </Button>
        </Box>
      ) : (
        <HStack justify="center">
          <Spinner />
          <Text>Initializing</Text>
        </HStack>
      )}
      {Object.keys(SUPPORTED_WALLETS).map((key) => {
        const option = SUPPORTED_WALLETS[key];
        if (option.connector === connector) {
          if (option.connector === injected) {
            if (isMetamask && option.name !== "MetaMask") {
              return null;
            }
            if (!isMetamask && option.name === "MetaMask") {
              return null;
            }
          }
          return (
            <WalletOption
              id={`connect-${key}`}
              key={key}
              clickable={false}
              color={option.color}
              header={option.name}
              subheader={option.description}
              icon={option.iconURL}
            />
          );
        }
        return null;
      })}
    </VStack>
  );
}
