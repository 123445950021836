import React from 'react'
import {
  VStack,
  HStack,
  Link,
  Text,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  Box,
  Button,
} from '@chakra-ui/react'
import { FiExternalLink } from 'react-icons/fi'

import { useActiveWeb3React } from '../../hooks'
import { useAppDispatch } from '../../app/hooks'
import { updateWalletStatus } from '../../state/wallet/walletSlice'
import useAuth from '../../hooks/useAuth'
import { shortenAddress } from '../../utils'
import { Copy } from './Copy'

import { SUPPORTED_WALLETS } from '../../constants'
import { getEtherscanLink } from '../../utils'
import { injected } from '../../connectors'
import Identicon from '../Identicon'

interface AccountDetailsProps {
  toggleWalletModal: () => void
  ENSName?: string
  openOptions: () => void
}

export default function AccountDetails({
  ENSName,
  openOptions,
}: AccountDetailsProps) {
  const { chainId, account, connector } = useActiveWeb3React()
  const { logout } = useAuth()
  const dispatch = useAppDispatch()

  if (account) {
    dispatch(updateWalletStatus(account))
  } else {
    dispatch(updateWalletStatus(''))
  }

  function formatConnectorName() {
    const { ethereum } = window
    const isMetaMask = !!(ethereum && ethereum.isMetaMask)
    const name = Object.keys(SUPPORTED_WALLETS)
      .filter(
        (k) =>
          SUPPORTED_WALLETS[k].connector === connector &&
          (connector !== injected || isMetaMask === (k === 'METAMASK')),
      )
      .map((k) => SUPPORTED_WALLETS[k].name)[0]
    return <Text fontWeight="light">Connected with {name}</Text>
  }

  return (
    <ModalContent borderRadius="xl">
      <ModalHeader>Account</ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <VStack
          spacing="30px"
          align="stretch"
          borderColor="gray.400"
          borderRadius="sm"
          p="2"
        >
          <HStack justify="space-between">
            {formatConnectorName()}
            <Box>
              <Button
                variant="outline"
                colorScheme="orange"
                size="sm"
                style={{ fontSize: '.825rem', fontWeight: 400 }}
                onClick={() => {
                  openOptions()
                }}
              >
                Change
              </Button>
            </Box>
          </HStack>
          <HStack justify="space-between">
            {ENSName ? (
              <HStack spacing="10px">
                <Identicon />
                <Text fontSize="xl" fontWeight="semibold">
                  {' '}
                  {ENSName}
                </Text>
              </HStack>
            ) : (
              <HStack spacing="10px">
                <Identicon />
                <Text fontSize="xl" fontWeight="semibold">
                  {' '}
                  {account && shortenAddress(account)}
                </Text>
              </HStack>
            )}
            {/* <Button size="sm" colorScheme="red" onClick={() => deactivate()}>
              Disconnect
            </Button> */}
            <Link href={`/creator/${account}`}>View Profile</Link>
          </HStack>
          <HStack spacing="30px" justify="space-between">
            {account && <Copy toCopy={account} />}
            {chainId && account && (
              <Link
                isExternal
                href={getEtherscanLink(chainId, account, 'address')}
              >
                <HStack>
                  <FiExternalLink />
                  <Text fontWeight="light" fontSize="sm">
                    View on Bscscan
                  </Text>
                </HStack>
              </Link>
            )}
          </HStack>
          <Button
            colorScheme="red"
            style={{
              fontSize: '.825rem',
              fontWeight: 400,
              marginRight: '8px',
            }}
            onClick={() => {
              logout()
            }}
            isFullWidth
          >
            Disconnect
          </Button>
        </VStack>
      </ModalBody>
    </ModalContent>
  )
}
