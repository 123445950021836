import axios from "axios";

export function fetchPrice(symbol: string = "binancecoin") {
  return new Promise<{ price: number }>(async (resolve) => {
    try {
      const r1 = await axios.get(
        `https://api.coingecko.com/api/v3/simple/price?ids=${symbol}&vs_currencies=usd`,
        {
          headers: {
            "X-CMC_PRO_API_KEY": "7e0857ea-a516-4af4-84e4-0d0aeadf5b21",
            "Content-Type": "application/json",
          },
        }
      );
      const response = r1.data;
      if (r1.status === 200 && r1.data) {
        const assetData = response[symbol].usd;
        return resolve({ price: assetData });
      }
    } catch (error) {
      console.error("failed fetching price data", error);
    }
  });
}
