import React from "react";
import { Box } from "@chakra-ui/react";

export default function Identicon() {
  return (
    <Box
      maxW="64px"
      maxh="64px"
      minW="32px"
      minH="32px"
      bgGradient="linear(to-l, #7928CA, #FF0080)"
      borderRadius="50%"
    />
  );
}
