import React, { useState, useEffect, useCallback } from "react";
import { useWeb3React } from "@web3-react/core";
import { useTranslation } from "react-i18next";
import { Spinner, Flex, Text } from "@chakra-ui/react";

import { network } from "../../connectors";
import { useEagerConnect, useInactiveListener } from "../../hooks";
import { NetworkContextName } from "../../constants";
import { updateWalletStatus } from "../../state/wallet/walletSlice";
import { store } from "../../app/store";

export default function Web3ReactManager({
  children,
}: {
  children: JSX.Element;
}) {
  const { t } = useTranslation();
  const { active, account } = useWeb3React();
  const {
    active: networkActive,
    error: networkError,
    activate: activateNetwork,
  } = useWeb3React(NetworkContextName);
  const dispatch = store.dispatch;

  // try to eagerly connect to an injected provider, if it exists and has granted access already
  const triedEager = useEagerConnect();

  const updateWalletAddress = useCallback(() => {
    triedEager && dispatch(updateWalletStatus(account as string));
  }, [account, dispatch, triedEager]);

  // after eagerly trying injected, if the network connect ever isn't active or in an error state, activate itd
  useEffect(() => {
    if (triedEager && !networkActive && !networkError && !active) {
      activateNetwork(network);
    }

    updateWalletAddress();
  }, [
    triedEager,
    networkActive,
    networkError,
    activateNetwork,
    active,
    updateWalletAddress,
  ]);

  // when there's no account connected, react to logins (broadly speaking) on the injected provider, if it exists
  useInactiveListener(!triedEager);

  // handle delayed loader state
  const [showLoader, setShowLoader] = useState(false);
  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowLoader(true);
    }, 600);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  // on page load, do nothing until we've tried to connect to the injected connector
  if (!triedEager) {
    return null;
  }

  type MessageProps = {
    children: React.ReactNode;
  };

  const MessageWrapper = ({ children }: MessageProps) => {
    return (
      <Flex align="center" justify="center" height="20rem">
        {children}
      </Flex>
    );
  };

  // if the account context isn't active, and there's an error on the network context, it's an irrecoverable error
  if (!active && networkError) {
    return (
      <MessageWrapper>
        <Text color="gray.500">{t("unknownError")}</Text>
      </MessageWrapper>
    );
  }

  // if neither context is active, spin
  if (!active && !networkActive) {
    return showLoader ? (
      <MessageWrapper>
        <Spinner size="lg" />
      </MessageWrapper>
    ) : null;
  }

  return children;
}
