import { useMemo } from 'react'
import { Contract } from '@ethersproject/contracts'

import { useActiveWeb3React } from '.'
import {
  getMart3Contract,
  getArtist3NftContract,
} from '../utils/contractHelpers'
import { getMulticallAddress } from '../utils/addressHelpers'
import { getContract } from '../utils'

import MULTICALL_ABI from '../abis/multicall2.json'
import ERC20_ABI from '../abis/erc20.json'

export const useMart3Contract = () => {
  const { library } = useActiveWeb3React()
  return useMemo(() => getMart3Contract(library?.getSigner()), [library])
}

export const useArtist3NftContract = () => {
  const { library } = useActiveWeb3React()
  return useMemo(() => getArtist3NftContract(library?.getSigner()), [library])
}

// Code below migrated from Exchange useContract.ts

// returns null on errors
function useContract(
  address: string | undefined,
  ABI: any,
  withSignerIfPossible = true,
): Contract | null {
  const { library, account } = useActiveWeb3React()

  return useMemo(() => {
    if (!address || !ABI || !library) return null
    try {
      return getContract(
        address,
        ABI,
        library,
        withSignerIfPossible && account ? account : undefined,
      )
    } catch (error) {
      console.error('Failed to get contract', error)
      return null
    }
  }, [address, ABI, library, withSignerIfPossible, account])
}

export function useMulticall2Contract(): Contract | null {
  return useContract(getMulticallAddress(), MULTICALL_ABI, false)
}

export function useTokenContract(
  tokenAddress?: string,
  withSignerIfPossible?: boolean,
): Contract | null {
  return useContract(tokenAddress, ERC20_ABI, withSignerIfPossible)
}
