import React from "react";
import { Text, VStack } from "@chakra-ui/react";

const NoMatch = () => {
  return (
    <VStack align="center" justify="center" my="50px">
      <Text fontSize="7xl" fontWeight="bold" textAlign="center">
        ERROR 404
      </Text>
    </VStack>
  );
};

export default NoMatch;
