import { useMemo } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { updateBalance, selectBalance } from "./balanceSlice";

export const useBalance = (): {
  balance: number;
  updateWalletBalance: (balance: number) => void;
} => {
  const balance = useAppSelector(selectBalance);
  const dispatch = useAppDispatch();

  const updateWalletBalance = useMemo(
    () => (state: number) => {
      dispatch(updateBalance(state));
    },
    [dispatch]
  );

  return { balance, updateWalletBalance };
};
