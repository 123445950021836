import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

export interface BalanceState {
  balance: number;
  updatedAt: number;
  isRefreshing: boolean;
}

const initialState: BalanceState = {
  balance: 0,
  updatedAt: 0,
  isRefreshing: false,
};

export const balanceSlice = createSlice({
  name: "balance",
  initialState,
  reducers: {
    updateBalance: (state, action: PayloadAction<number>) => {
      state.balance = action.payload;
      state.updatedAt = Date.now();
    },
  },
});

export const { updateBalance } = balanceSlice.actions;

export const selectBalance = (state: RootState) => state.balance.balance;

export default balanceSlice.reducer;
