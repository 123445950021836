import React, { ErrorInfo } from "react";
import { RootState, store } from "../../app/store";
import { Box, Text, VStack, Link } from "@chakra-ui/react";
import { FaSurprise } from "react-icons/fa";
import ReactGA from "react-ga";
import { getUserAgent } from "../../utils/getUserAgent";

type ErrorBoundaryState = {
  error: Error | null;
};

export default class ErrorBoundary extends React.Component<
  unknown,
  ErrorBoundaryState
> {
  constructor(props: unknown) {
    super(props);
    this.state = { error: null };
  }

  static getDerivedStateFromError(error: Error): ErrorBoundaryState {
    return { error };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    ReactGA.exception({
      ...error,
      ...errorInfo,
      fatal: true,
    });
  }

  render() {
    const { error } = this.state;
    if (error !== null) {
      const encodedBody = encodeURIComponent(issueBody(error));
      return (
        <VStack align="center" justify="center" h="100vh">
          <Box fontSize="3xl">
            <FaSurprise />
          </Box>
          <Text>Something went wrong</Text>
          <Link
            href={`https://github.com/Artist3-NFT/mart3-interface/issues/new?assignees=&labels=bug&body=${encodedBody}&title=${encodeURIComponent(
              `Crash report: \`${error.name}${
                error.message && `: ${error.message}`
              }\``
            )}`}
            isExternal
          >
            Create an issue on Github
          </Link>
        </VStack>
      );
    }
    return this.props.children;
  }
}

function getRelevantState(): null | keyof RootState {
  const path = window.location.hash;
  if (!path.startsWith("#/")) {
    return null;
  }
  const pieces = path.substring(2).split(/[/\\?]/);
  switch (pieces[0]) {
    case "explore":
      return "application";
    case "creator":
      if (pieces[2] === "collection") return "application";
      else return "application";
  }
  return null;
}

function issueBody(error: Error): string {
  const relevantState = getRelevantState();
  const deviceData = getUserAgent();
  return `## URL
  
${window.location.href}

${
  relevantState
    ? `## \`${relevantState}\` state
    
\`\`\`json
${JSON.stringify(store.getState()[relevantState], null, 2)}
\`\`\`
`
    : ""
}
${
  error.name &&
  `## Error

\`\`\`
${error.name}${error.message && `: ${error.message}`}
\`\`\`
`
}
${
  error.stack &&
  `## Stacktrace

\`\`\`
${error.stack}
\`\`\`
`
}
${
  deviceData &&
  `## Device data

\`\`\`json
${JSON.stringify(deviceData, null, 2)}
\`\`\`
`
}
`;
}
